// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-2021-abc-js": () => import("./../src/pages/2021/abc.js" /* webpackChunkName: "component---src-pages-2021-abc-js" */),
  "component---src-pages-2021-coffee-js": () => import("./../src/pages/2021/coffee.js" /* webpackChunkName: "component---src-pages-2021-coffee-js" */),
  "component---src-pages-2021-valentine-js": () => import("./../src/pages/2021/valentine.js" /* webpackChunkName: "component---src-pages-2021-valentine-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-demo-index-js": () => import("./../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-demo-newyear-2021-js": () => import("./../src/pages/demo/newyear2021.js" /* webpackChunkName: "component---src-pages-demo-newyear-2021-js" */),
  "component---src-pages-demo-slideshow-js": () => import("./../src/pages/demo/slideshow.js" /* webpackChunkName: "component---src-pages-demo-slideshow-js" */),
  "component---src-pages-demo-videoslider-js": () => import("./../src/pages/demo/videoslider.js" /* webpackChunkName: "component---src-pages-demo-videoslider-js" */),
  "component---src-pages-demo-yarn-js": () => import("./../src/pages/demo/yarn.js" /* webpackChunkName: "component---src-pages-demo-yarn-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-js": () => import("./../src/pages/internal.js" /* webpackChunkName: "component---src-pages-internal-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-samples-js": () => import("./../src/pages/samples.js" /* webpackChunkName: "component---src-pages-samples-js" */)
}

